import React, { useContext, useState } from 'react';
import { GlobalState } from '@models/common/GlobalState';

interface ApplicationContext {
  globalState: GlobalState;
  setGlobalState: (values: GlobalState) => void;
}

const ApplicationStateContext = React.createContext<ApplicationContext>({} as ApplicationContext);

interface ApplicationProviderProps {
  children: any;
  state: GlobalState;
}

export const ApplicationStateProvider: React.FC<ApplicationProviderProps> = ({ children, state }) => {
  const [globalState, setGlobalState] = useState(state);

  return (
    <ApplicationStateContext.Provider value={{ globalState, setGlobalState }}>
      {children}
    </ApplicationStateContext.Provider>
  );
};

export const useApplicationState = () => {
  return useContext(ApplicationStateContext);
};

import { createContext } from 'react';
import { ProceedPatientData } from '@components/Shared/NewPatientSearch/NewManualPatient.constants';
import { WalkInReferral } from '@NewCPCS/ClinicalPathwaysWalkIn/walkInReferral.constant';
import { Referral } from '@NewCPCS/addReferral/AddReferral.constants';

interface PatientContextType {
  patientData: ProceedPatientData | null;
  updatePatientData: (patientData: ProceedPatientData) => void;
  clearPatientData: () => void;
  referralData: WalkInReferral | Referral | null;
  updateReferralData: (referralData: WalkInReferral | Referral) => void;
  clearReferralData: () => void;
}

export const PatientContext = createContext<PatientContextType | null>(null);

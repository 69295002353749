import { User } from './User';

export interface GlobalState {
  user: User;
}

const globalState = {
  user: new User(),
} as GlobalState;

export default globalState;

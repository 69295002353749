import React from 'react';
import {
  ActionsLayout,
  Form,
  FormText,
  getFieldProps,
  PrimaryButton,
  TertiaryButton,
  TextField,
} from '@crx-dev/hss-react-components';
import { useFormik } from 'formik';
import styles from './LocumDetailsForm.module.css';
import { LocomDetailsFormFields } from '@models/common/LocumDetails';
import { LocumDetailsSchema } from './LocumDetails.constants';
import KeyCloakAuth from '@security/KeyCloakAuth';

interface LocumDetailsFormProps {
  onSubmit: (values: LocomDetailsFormFields) => void;
}

const LocumDetailsForm = ({ onSubmit }: LocumDetailsFormProps) => {
  const formik = useFormik<LocomDetailsFormFields>({
    initialValues: {
      firstName: '',
      lastName: '',
      gphcNumber: '',
    },
    validationSchema: LocumDetailsSchema,
    onSubmit,
  });

  return (
    <div className={styles.root}>
      <div className={styles.form}>
        <Form onSubmit={formik.handleSubmit}>
          <div className={styles.title}>Locum Details</div>
          <FormText>Please enter your details</FormText>

          <TextField
            maxLength={50}
            {...getFieldProps(formik, 'firstName')}
            label="First Name"
            placeholder="First Name"
          />

          <TextField
            maxLength={50}
            {...getFieldProps(formik, 'lastName')}
            label="Last Name"
            placeholder="Last Name"
          />

          <TextField
            maxLength={7}
            {...getFieldProps(formik, 'gphcNumber')}
            label="GPhC Number"
            placeholder="GPhC Number"
          />

          <ActionsLayout layout="inline">
            <TertiaryButton id="formCancel_btn" onClick={() => KeyCloakAuth.logout()}>
              Cancel
            </TertiaryButton>
            <PrimaryButton id="save" type="submit">
              Continue
            </PrimaryButton>
          </ActionsLayout>
        </Form>
      </div>
    </div>
  );
};

export default LocumDetailsForm;

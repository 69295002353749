import { useCallback, useState } from 'react';
import * as api from '@api/api';
import { instanceToInstance, plainToClass } from 'class-transformer';

export interface ApiRequestActions<T> {
  makeRequest: (data?: any) => void;
  setData: (data: T) => void;
  setUrl: (newPath: string) => void;
  makePromiseRequest: (data?: any) => Promise<T>;
  makeArrayPromiseRequest: (data?: any) => Promise<Array<T>>;
}

export default function useApiRequest<T>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  path: string,
  type: any,
  isAbsoultePath?: boolean,
  contentType?: string,
): [T, ApiRequestActions<T>, RequestStatus] {
  const [response, setResponse] = useState<T>([] as unknown as T);
  const initialStatus = { isPending: false, hasError: false, message: '' };
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(initialStatus);

  const makeRequest = useCallback(async (data?: any) => {
    try {
      setRequestStatus({
        isPending: true,
        hasError: false,
        message: 'Calling api',
      });

      const response = await api.makeRequest({
        method,
        path,
        data,
        isAbsoultePath,
        contentType,
      });
      if (response) {
        setResponse(plainToClass(type, response as T));
        setRequestStatus({ hasError: false, message: '', isPending: false });
      } else {
        setRequestStatus({ isPending: false, hasError: false, message: '' });
      }
    } catch (error) {
      setRequestStatus({
        isPending: false,
        hasError: true,
        message: 'Could not complete request',
      });
      //  log('Debug', `useApiRequest: Error: ${error.message}`);
    }
  }, []);
  const makePromiseRequest = useCallback(async (data?: any): Promise<T> => {
    //this method doesnot set the value just return it in a promise
    // it can be made to set the value if needed later
    try {
      setRequestStatus({
        isPending: true,
        hasError: false,
        message: 'Calling api',
      });
      const response = await api.makeRequest({
        method,
        path,
        data,
        isAbsoultePath,
        contentType,
      });
      return new Promise(function (resolve, reject) {
        if (response) {
          const responseClassForm: T = plainToClass(type, response as T);
          setRequestStatus({ hasError: false, message: '', isPending: false });
          return resolve(responseClassForm);
        }
        return reject(new Error('error parsing the returned result into the desired class'));
      });
    } catch (error) {
      setRequestStatus({
        isPending: false,
        hasError: true,
        message: 'Could not complete request',
      });
      return new Promise(function (resolve, reject) {
        reject(error);
      });
    }
  }, []);

  const makeArrayPromiseRequest = useCallback(async (data?: any): Promise<Array<T>> => {
    //this method doesnot set the value just return it in a promise
    // it can be made to set the value if needed later
    try {
      setRequestStatus({
        isPending: true,
        hasError: false,
        message: 'Calling api',
      });
      const response = await api.makeRequest({
        method,
        path,
        data,
        isAbsoultePath,
        contentType,
      });
      return new Promise(function (resolve, reject) {
        if (response) {
          const responseClassForm: Array<T> = instanceToInstance(response as Array<T>);
          setRequestStatus({
            hasError: false,
            message: '',
            isPending: false,
          });
          return resolve(responseClassForm);
        }
        return reject(new Error('error parsing the returned result into the desired class'));
      });
    } catch (error) {
      setRequestStatus({
        isPending: false,
        hasError: true,
        message: 'Could not complete request',
      });
      return new Promise(function (resolve, reject) {
        reject(error);
      });
    }
  }, []);

  const setPath = useCallback((newPath: string) => {
    path = newPath;
  }, []);

  return [
    response,
    {
      makeRequest,
      setData: setResponse,
      setUrl: setPath,
      makePromiseRequest,
      makeArrayPromiseRequest,
    },
    requestStatus,
  ];
}

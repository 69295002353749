import React, { useState, useMemo, ReactNode, useEffect, useCallback } from 'react';
import { ProceedPatientData } from '@components/Shared/NewPatientSearch/NewManualPatient.constants';
import { PatientContext } from '@context/PatientContext';
import { WalkInReferral } from '@NewCPCS/ClinicalPathwaysWalkIn/walkInReferral.constant';
import { Referral } from '@NewCPCS/addReferral/AddReferral.constants';
import { useLocation, Location } from 'react-router-dom-v6';
import { PagesPaths } from '@enums/PagesPathsEnum';

const preserveDataPaths: PagesPaths[] = [
  PagesPaths.CLINICAL_PATHWAYS_WALK_IN_PAGE,
  PagesPaths.ADD_REFERRAL_PAGE,
  PagesPaths.REFERRAL_PATIENT,
];

export const PatientProvider = ({ children }: { children: ReactNode }) => {
  const [patientData, setPatientData] = useState<ProceedPatientData | null>(null);
  const [referralData, setReferralData] = useState<WalkInReferral | Referral | null>(null);
  const location = useLocation();

  const updatePatientData = (data: ProceedPatientData) => {
    setPatientData(data);
  };

  const clearPatientData = useCallback(() => {
    setPatientData(null);
  }, []);

  const updateReferralData = (data: WalkInReferral | Referral) => {
    setReferralData(data);
  };

  const clearReferralData = useCallback(() => {
    setReferralData(null);
  }, []);

  useEffect(() => {
    const handleLocationChange = (newLocation: Location) => {
      if (!preserveDataPaths.includes(newLocation.pathname as PagesPaths)) {
        clearPatientData();
        clearReferralData();
      }
    };
    handleLocationChange(location);
  }, [clearPatientData, clearReferralData, location]);

  const value = useMemo(() => {
    return {
      patientData,
      updatePatientData,
      clearPatientData,
      referralData,
      updateReferralData,
      clearReferralData,
    };
  }, [clearPatientData, clearReferralData, patientData, referralData]);
  return <PatientContext.Provider value={value}>{children}</PatientContext.Provider>;
};

import React from 'react';
import { AppContainer } from '@crx-dev/hss-react-components';
import {
  FluVaccinationPagesPaths,
  HcfPagesPaths,
  NmsPagesPaths,
  PagesPaths,
  PcsPagesPaths,
} from '@enums/PagesPathsEnum';
import KeyCloakAuth from '@security/KeyCloakAuth';
import { CPCS, NEW_CPCS, DMS, FLUVAC, HCF, NMS, PCS } from '@src/base/roles';
import IdleTimeHandler from '../IdleTimeHandler/IdleTimeHandler';
import { useNavigate, useLocation, matchPath } from 'react-router-dom-v6';

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navHelper = (to: string, exact = false) => ({
    to,
    isSelected: !!matchPath({ path: to, end: exact }, location.pathname),
    onClick: (to: string) => navigate(to),
  });
  return (
    <>
      <IdleTimeHandler />
      <AppContainer.NavItem label="Dashboard" {...navHelper(PagesPaths.HOME, true)} />
      {(KeyCloakAuth.hasRole(CPCS) || KeyCloakAuth.hasRole(NEW_CPCS)) && (
        <AppContainer.NavItem label="Pharmacy First" {...navHelper(PagesPaths.REFERRALS, true)} />
      )}
      {KeyCloakAuth.hasRole(DMS) && (
        <AppContainer.NavItem
          label="Discharge Medicines"
          isNew
          {...navHelper(PagesPaths.DISCHARGE_MEDICINES_REFERRALS, true)}
        />
      )}
      {KeyCloakAuth.hasRole(NMS) && (
        <AppContainer.NavItem label="NMS" {...navHelper(NmsPagesPaths.NEW_MEDICINE_SERVICE, true)} />
      )}
      {KeyCloakAuth.hasRole(FLUVAC) && (
        <AppContainer.NavItem
          label="Flu Vaccination"
          {...navHelper(FluVaccinationPagesPaths.FLU_VACCINATION_SERVICE, true)}
        />
      )}
      {KeyCloakAuth.hasRole(HCF) && (
        <AppContainer.NavItem
          label="Hypertension Case-Finding"
          {...navHelper(HcfPagesPaths.HCF_SERVICE, true)}
        />
      )}
      {KeyCloakAuth.hasRole(PCS) && (
        <AppContainer.NavItem label="Contraception" {...navHelper(PcsPagesPaths.PCS_SERVICE, true)} />
      )}
      <AppContainer.NavItem
        label="Help"
        to="https://help.cegedim-healthcare.co.uk/Pharmacy_Services/Content/Home.htm"
        external
      />
    </>
  );
};
export default SideBar;

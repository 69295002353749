import * as yup from 'yup';

export const LocumDetailsSchema = yup.object().shape({
  firstName: yup.string().required('Field cannot be blank'),
  lastName: yup.string().required('Field cannot be blank'),
  gphcNumber: yup
    .string()
    .required('Field cannot be blank')
    .matches(/^[0-9]+$/, 'Field must be a number')
    .min(7, 'Must be exactly 7 characters')
    .max(7, 'Must be exactly 7 characters'),
});
